<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <!-- <v-icon @click="$router.go(-1)">mdi-keyboard-backspace</v-icon> -->
            Top Collections
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
            <!-- <v-row justify="end" class="px-5">
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="red" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addcat" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Top Collection</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              dense
                              v-model="collectionid"
                              class="mt-5"
                              label="Sub Category"
                              :items="subcategoryArray"
                              item-text="name"
                              item-value="_id"
                              :rules="Rules"
                              required
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              dense
                              v-model="product"
                              class="mt-5"
                              label="Product"
                              :items="productArray"
                              item-text="productname"
                              item-value="_id"
                              :rules="Rules"
                              required
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="checkbox"
                              label="Is Highlight"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addcat"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row> -->
          </v-card-title>
          <v-layout wrap justify-center pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >ACCESSORIES</span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md4 lg8 xl8 text-left pl-md-4 v-if="acc">
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000">{{
                    acc.title
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    acc.subtitle
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    acc.caption
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pl-md-4>
              <v-dialog
                :retain-focus="false"
                v-model="dialogAcc"
                persistent
                max-width="600px"
                :key="dialogAcc"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Accessories Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="accessoriestitle"
                              label="Accessories Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="accessoriessubtitle"
                              label="Accessories Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="accessoriescaption"
                              label="Accessories Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogAcc = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex>
                    <v-select
                      dense
                      v-model="itemediting.collectionid"
                      class="mt-5"
                      label="Subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(itemediting._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Products -->
          <v-layout wrap pt-10 pb-10>
            <v-flex md3 pa-4 v-for="(item, i) in user.ACCESSORIES" :key="i">
              <v-card tile flat>
                <div v-if="item.product" v-viewer style="display: flex">
                  <img
                    style="object-fit: contain; cursor: pointer; width: 100%"
                    :src="baseURL + item.product.photos[0]"
                    height="300px"
                    cover
                  />
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <!-- </v-img> -->
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 md12 xs12 sm12 lg12>
                    <span
                      v-if="item.product"
                      style="font-size: 13px; color: #000000"
                    >
                      {{ item.product.productname }} </span
                    ><br />
                    <!-- <span style="font-size: 13px; color: #000000">
                      Is Highlight: {{ item.ishighlight }}
                    </span> -->
                  </v-flex>
                  <v-flex pt-5 pb-2 px-4 md6 sm6 xs6 x6 justify-center>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          outlined
                          block
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Collection?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- <v-col cols="6" md="6">
                        <v-dialog v-model="item.edit" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              outlined
                              class=""
                              v-on="on"
                              v-bind="attrs"
                              color="red"
                              @click="itemedit(item)"
                            >
                              Edit
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Edit Top Collection</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      dense
                                      v-model="itemediting.collectionid"
                                      class="mt-5"
                                      label="Subcategory"
                                      :items="subcategoryArray"
                                      item-text="name"
                                      item-value="_id"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      v-if="itemediting.product"
                                      dense
                                      v-model="itemediting.product"
                                      class="mt-5"
                                      label="Product"
                                      :items="productArray"
                                      item-text="productname"
                                      item-value="_id"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-checkbox
                                      v-model="checkbox"
                                      label="Is Highlight"
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.edit = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn color="blue darken-1" text @click="edit()">
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col> -->
                  </v-flex>
                  <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                    <v-btn
                      small
                      block
                      class="mr-2"
                      outlined
                      color="green"
                      @click="itemedit(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >SHOES
                </span></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md4 lg8 xl8 text-left pl-md-4 v-if="shoe">
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000">{{
                    shoe.title
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    shoe.subtitle
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    shoe.caption
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pl-md-4>
              <v-dialog
                :retain-focus="false"
                v-model="dialogSh"
                persistent
                max-width="600px"
                :key="dialogSh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Shoes Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="shoestitle"
                              label="Shoes Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="shoessubtitle"
                              label="Shoes Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="shoescaption"
                              label="Shoes Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogSh = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex>
                    <v-select
                      dense
                      v-model="itemediting.collectionid"
                      class="mt-5"
                      label="Subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(itemediting._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Products -->
          <v-layout wrap pt-10 pb-10>
            <v-flex md3 pa-4 v-for="(item, i) in user.SHOES" :key="i">
              <v-card tile flat>
                <div v-if="item.product" v-viewer style="display: flex">
                  <img
                    style="cursor: pointer; width: 100%; object-fit: contain"
                    :src="baseURL + item.product.photos[0]"
                    height="300px"
                    cover
                  />
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <!-- </v-img> -->
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 md12 lg12 xs12 sm12>
                    <span
                      v-if="item.product"
                      style="font-size: 13px; color: #000000"
                    >
                      {{ item.product.productname }}</span
                    ><br />
                    <!-- <span style="font-size: 13px; color: #000000">
                      Is Highlight: {{ item.ishighlight }}
                    </span> -->
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex pt-5 pb-2 px-4 md6 sm6 xs6 xl6 justify-center>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          class="mr-2"
                          v-on="on"
                          outlined
                          block
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Collection?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- <v-col cols="6" md="6">
                        <v-dialog v-model="item.edit" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class=""
                              outlined
                              v-on="on"
                              v-bind="attrs"
                              color="red"
                              @click="itemedit(item)"
                            >
                              Edit
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Edit Top Collection</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      dense
                                      v-model="itemediting.collectionid"
                                      class="mt-5"
                                      label="Subcategory"
                                      :items="subcategoryArray"
                                      item-text="name"
                                      item-value="_id"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      v-if="itemediting.product"
                                      dense
                                      v-model="itemediting.product"
                                      class="mt-5"
                                      label="Product"
                                      :items="productArray"
                                      item-text="productname"
                                      item-value="_id"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-checkbox
                                      v-model="checkbox"
                                      label="Is Highlightng"
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.edit = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn color="blue darken-1" text @click="edit()">
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row> -->
                    <br />
                  </v-flex>
                  <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      color="green"
                      @click="itemedit(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >BAGS</span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md4 lg8 xl8 text-left pl-md-4 v-if="bagData">
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000">{{
                    bagData.title
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    bagData.subtitle
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    bagData.caption
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pl-md-4>
              <v-dialog
                :retain-focus="false"
                v-model="dialogBag"
                persistent
                max-width="600px"
                :key="dialogSh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Bag Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="bagstitle"
                              label="Bag Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="bagssubtitle"
                              label="Bag Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="bagscaption"
                              label="Bag Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogBag = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex>
                    <v-select
                      dense
                      v-model="itemediting.collectionid"
                      class="mt-5"
                      label="Subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(itemediting._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Products -->
          <v-layout wrap pt-10 pb-10>
            <v-flex md3 pa-4 v-for="(item, i) in user.BAGS" :key="i">
              <v-card tile flat>
                <div v-if="item.product" v-viewer style="display: flex">
                  <img
                    style="cursor: pointer; object-fit: contain; width: 100%"
                    :src="baseURL + item.product.photos[0]"
                    height="300px"
                    cover
                  />
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <!-- </v-img> -->
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 md12 xs12 lg12 sm12>
                    <span
                      v-if="item.product"
                      style="font-size: 13px; color: #000000"
                    >
                      {{ item.product.productname }} </span
                    ><br />
                    <!-- <span style="font-size: 13px; color: #000000">
                      Is Highlight: {{ item.ishighlight }}
                    </span> -->
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex pt-5 pb-2 px-4 md6 sm6 xs6 xl6 lg6 justify-center>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          outlined
                          block
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Collection?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <br />
                  </v-flex>
                  <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      color="green"
                      @click="itemedit(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout wrap justify-center pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >WEDDING  </span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md4 lg8 xl8 text-left pl-md-4 v-if="weddingData">
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000">{{
                    weddingData.title
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    weddingData.subtitle
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    weddingData.caption
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pl-md-4>
              <v-dialog
                :retain-focus="false"
                v-model="dialogWed"
                persistent
                max-width="600px"
                :key="dialogSh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Wedidng Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="weddingtitle"
                              label="Wedding Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="weddingsubtitle"
                              label="Wedding Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="weddingcaption"
                              label="Wedding Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogWed = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex>
                    <v-select
                      dense
                      v-model="itemediting.collectionid"
                      class="mt-5"
                      label="Subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(itemediting._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Products -->
          <v-layout wrap pt-10 pb-10>
            <v-flex md3 pa-4 v-for="(item, i) in user.WEDDING" :key="i">
              <v-card tile flat>
                <div v-if="item.product" v-viewer style="display: flex">
                  <img
                    style="cursor: pointer; object-fit: contain; width: 100%"
                    :src="baseURL + item.product.photos[0]"
                    height="300px"
                    cover
                  />
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <!-- </v-img> -->
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 md12 xs12 lg12 sm12>
                    <span
                      v-if="item.product"
                      style="font-size: 13px; color: #000000"
                    >
                      {{ item.product.productname }} </span
                    ><br />
                    <!-- <span style="font-size: 13px; color: #000000">
                      Is Highlight: {{ item.ishighlight }}
                    </span> -->
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex pt-5 pb-2 px-4 md6 sm6 xs6 xl6 lg6 justify-center>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          outlined
                          block
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Collection?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <br />
                  </v-flex>
                  <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      color="green"
                      @click="itemedit(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout wrap justify-center pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >FESTIVAL  </span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md4 lg8 xl8 text-left pl-md-4 v-if="festivalData">
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000">{{
                    festivalData.title
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    festivalData.subtitle
                  }}</span>
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000">{{
                    festivalData.caption
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg2 xl2 text-left pl-md-4>
              <v-dialog
                :retain-focus="false"
                v-model="dialogFest"
                persistent
                max-width="600px"
                :key="dialogSh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Festival Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="festivaltitle"
                              label="Festival Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="festivalsubtitle"
                              label="Festival Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="festivalcaption"
                              label="Festival Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogFest = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex>
                    <v-select
                      dense
                      v-model="itemediting.collectionid"
                      class="mt-5"
                      label="Subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(itemediting._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Products -->
          <v-layout wrap pt-10 pb-10>
            <v-flex md3 pa-4 v-for="(item, i) in user.BAGS" :key="i">
              <v-card tile flat>
                <div v-if="item.product" v-viewer style="display: flex">
                  <img
                    style="cursor: pointer; object-fit: contain; width: 100%"
                    :src="baseURL + item.product.photos[0]"
                    height="300px"
                    cover
                  />
                </div>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <!-- </v-img> -->
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 md12 xs12 lg12 sm12>
                    <span
                      v-if="item.product"
                      style="font-size: 13px; color: #000000"
                    >
                      {{ item.product.productname }} </span
                    ><br />
                    <!-- <span style="font-size: 13px; color: #000000">
                      Is Highlight: {{ item.ishighlight }}
                    </span> -->
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex pt-5 pb-2 px-4 md6 sm6 xs6 xl6 lg6 justify-center>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          outlined
                          block
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Collection?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <br />
                  </v-flex>
                  <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      color="green"
                      @click="itemedit(item)"
                    >
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      msg: "",
      ServerError: false,
      collectionid: "",
      widthOfCard: "250px",
      product: "",
      addcat: false,
      checkbox: true,
      appLoading: false,
      dialogDelete: false,
      dialog: false,
      dialogAcc: false,
      dialogSh: false,
      dialogBag: false,
      dialogWed: false,
      dialogFest: false,
      editdialog: false,
      subcategoryArray: [],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
      search: "",
      currentPage: 1,
      pages: 0,
      accessoriestitle: null,
      accessoriessubtitle: null,
      accessoriescaption: null,
      shoestitle: null,
      shoessubtitle: null,
      shoescaption: null,
      bagstitle: null,
      bagssubtitle: null,
      bagscaption: null,
      weddingtitle: null,
      weddingsubtitle: null,
      weddingcaption: null,
      festivaltitle: null,
      festivalsubtitle: null,
      festivalcaption: null,
      productArray: [],
      itemediting: [],
      acc: [],
      shoe: [],
      bagData: [],
      weddingData: [],
      festivalData: [],
      addslider: false,
      totalData: 0,
      count: 60,
      totalRows: 0,
      Rules: [(value) => !!value || "Required."],
      user: [],
    };
  },
  mounted() {
    this.getData();
    this.getSubcategory();
    this.getTitle();
  },
  watch: {
    collectionid() {
      this.getproduct();
    },
  },
  methods: {
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/topcollection/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    itemedit(item) {
      this.itemediting = item;
      this.checkbox = this.itemediting.ishighlight;
      this.editdialog = true;
    },

    edit() {
      this.appLoading = true;
      var user = {};
      user["collectionid"] = this.itemediting.collectionid;
      user["product"] = this.itemediting.product._id;
      // user["ishighlight"] = this.checkbox;
      user["id"] = this.itemediting._id;
      axios({
        method: "POST",
        url: "/home/topcollection/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    dialogclose() {
      this.dialog = false;
    },
    getproduct() {
      axios({
        url: "/product/all",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          subcategory: this.collectionid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          (this.productArray = response.data.data), name;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/home/topcollection/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.shoes = this.user.SHOES;
          this.accessories = this.user.ACCESSORIES;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      axios({
        url: "/subcategory/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          var temp = [];
          temp = response.data.data;
          temp.reverse();
          this.subcategoryArray = temp;
          this.sizeArray = response.data.data.size;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getTitle() {
      this.appLoading = true;
      axios({
        url: "/home/titles/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.acc = response.data.data.accessories;
          this.shoe = response.data.data.shoes;
          this.bagData = response.data.data.bags;
          this.weddingData = response.data.data.wedding;
          this.festivalData = response.data.data.festival;
          this.accessoriestitle = this.acc.title;
          this.accessoriessubtitle = this.acc.subtitle;
          this.accessoriescaption = this.acc.caption;
          this.shoestitle = this.shoe.title;
          this.shoessubtitle = this.shoe.subtitle;
          this.shoescaption = this.shoe.caption;
          this.bagstitle = this.bagData.title;
          this.bagssubtitle = this.bagData.subtitle;
          this.bagscaption = this.bagData.caption;
          this.weddingtitle = this.weddingData.title;
          this.weddingsubtitle = this.weddingData.subtitle;
          this.weddingcaption = this.weddingData.caption;
          this.festivaltitle = this.festivalData.title;
          this.festivalsubtitle = this.festivalData.subtitle;
          this.festivalcaption = this.festivalData.caption;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    titleAdd() {
      var data = {};
      data["accessoriestitle"] = this.accessoriestitle;
      data["accessoriessubtitle"] = this.accessoriessubtitle;
      data["accessoriescaption"] = this.accessoriescaption;
      data["shoestitle"] = this.shoestitle;
      data["shoessubtitle"] = this.shoessubtitle;
      data["shoescaption"] = this.shoescaption;
      data["bagstitle"] = this.bagstitle;
      data["bagssubtitle"] = this.bagssubtitle;
      data["bagscaption"] = this.bagscaption;
      data["weddingtitle"] = this.weddingtitle;
      data["weddingsubtitle"] = this.weddingsubtitle;
      data["weddingcaption"] = this.weddingcaption;
      data["festivaltitle"] = this.festivaltitle;
      data["festivalsubtitle"] = this.festivalsubtitle;
      data["festivalcaption"] = this.festivalcaption;
      axios({
        url: "/home/titles/update",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.trendingtitle = null;
            this.trendingsubtitle = null;
            this.trendingcaption = null;
            this.dialogAcc = false;
            this.dialogSh = false;
            this.dialogWed = false;
            this.dialogFest = false;
            this.dialogBag = false;
            this.getTitle();
            // this.$router.push("/home/trendingList");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["collectionid"] = this.collectionid;
      data["product"] = this.product;
      data["ishighlight"] = this.checkbox;

      axios({
        url: "/home/topcollection/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true; 
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>